import React, { useState } from "react"

import { Card } from "./card"
import useCards from "../hooks/use-cards"
import { Hobbie } from "./hobbies"
import useHobbies from "../hooks/use-hobbies"

const About = () => {
  const [isToggle, setToggle] = useState(false)

  const cards = useCards()
  const hobbies = useHobbies()

  const cardsFiltered = cards.filter(card => card.link !== "/about")
  const hobbiesFiltered = hobbies.filter(
    hobbie => hobbie.heading1 !== "Funksion"
  )
  return (
    <div className="h-full flex flex-col items-center space-y-4 lg:max-w-full lg:transform xl:translate-x-20">
      <div className="w-full   ">
        <h1>/About me</h1>
        <p className="subtitle mt-2">Coding is not only my passion.</p>
      </div>
      <div className="lg:flex lg:flex-col xl:grid md:grid-col-1 xl:grid-cols-2 xl:w-full">
        <div>
          {hobbiesFiltered.map(hobbie => (
            <div>
              {hobbie.heading1 === "Chasing Inspiration Around the Globe" && (
                <Hobbie
                  className="w-72 lg:w-full xl:w-96 my-3 border-b border-neutral-02 xl:border-none xl:my-8 break-normal"
                  icon={hobbie.icon}
                  heading1={hobbie.heading1}
                  heading2={hobbie.heading2}
                />
              )}
            </div>
          ))}
          {hobbiesFiltered.map(hobbie => (
            <div>
              {hobbie.heading1 === "Climbing taught me to learn" && (
                <Hobbie
                  className="w-72 lg:w-full xl:w-96 my-3 border-b border-neutral-02 xl:border-none xl:my-8 break-normal"
                  icon={hobbie.icon}
                  heading1={hobbie.heading1}
                  heading2={hobbie.heading2}
                />
              )}
            </div>
          ))}
        </div>
        <div className="xl:ml-2 w-full">
          {hobbiesFiltered.map(hobbie => (
            <div>
              {hobbie.heading1 === "I love playing with nature" && (
                <Hobbie
                  className="w-72 lg:w-full xl:w-96 my-3 border-b border-neutral-02 xl:border-none xl:my-8 break-normal"
                  icon={hobbie.icon}
                  heading1={hobbie.heading1}
                  heading2={hobbie.heading2}
                />
              )}
            </div>
          ))}
          {hobbiesFiltered.map(hobbie => (
            <div className="cursor-pointer" onClick={() => setToggle(true)}>
              {hobbie.heading1 === "Music is always with me" && (
                <>
                  {isToggle === false && (
                    <Hobbie
                      className="w-72 lg:w-full xl:w-96 my-3 border-b border-neutral-02 xl:border-none xl:my-8 break-normal"
                      icon={hobbie.icon}
                      heading1={hobbie.heading1}
                      heading2={hobbie.heading2}
                    />
                  )}
                  {isToggle === true && (
                    <iframe
                      className="w-72 xl:w-80 h-20 rounded shadow-lg"
                      src="https://open.spotify.com/embed/album/5ckt4gstaOg1VlCqyLtrLT?theme=0"
                      frameBorder="0"
                      allowtransparency="true"
                      autoplay
                      allow="encrypted-media"
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col lg:hidden">
        {cards.map(
          card =>
            card.link === "/portfolio" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/contact" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
      </div>
    </div>
  )
}

export default About
