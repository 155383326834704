import * as React from "react"

import Layout from "../components/layout"

import About from "../components/about"

const AboutPage = () => (
  <Layout page="About">
    <About />
  </Layout>
)

export default AboutPage
